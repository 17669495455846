module.exports = function( $ ) {
    var $carousel = $( this ).find( '.elementor-blog-carousel' );
    if ( ! $carousel.length ) {
        return;
    }

    var savedOptions = $carousel.data( 'slider_options' ),
        defaultOptions = {
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: savedOptions.slidesToShowTablet,
                        slidesToScroll: savedOptions.slidesToShowTablet
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: savedOptions.slidesToShowMobile,
                        slidesToScroll: savedOptions.slidesToShowMobile
                    }
                }
            ]
        },

        slickOptions = $.extend( {}, defaultOptions, $carousel.data( 'slider_options' ) );

    $carousel.slick( slickOptions );
};
