module.exports = function( $ ) {

	var $instagramWrapper = $( this ).find( '.elementor-instagram' );
    var $carousel = $( this ).find( '.elementor-instagram-carousel' );

	if ( ! $instagramWrapper.length ) {
		return;
	}

	var options = $instagramWrapper.data( 'options' );

	if (options.token == '' ) {
		return;
	}

	$instagramWrapper.instagramLite({
		accessToken: options.token,
		limit: options.limit,
		urls: options.linked,
		element_class: options.class,
		comments_count: true,
		likes: true,
		videos: false,
		date: false,
		list: true,
		captions: false,
        success: function() {

            if ( ! $carousel.length ) {
                return;
            }

            var savedOptions = $carousel.data( 'slider_options' ),
                defaultOptions = {
                    responsive: [
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: savedOptions.slidesToShowTablet,
                                slidesToScroll: savedOptions.slidesToShowTablet
                            }
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: savedOptions.slidesToShowMobile,
                                slidesToScroll: savedOptions.slidesToShowMobile
                            }
                        }
                    ]
                },

                slickOptions = $.extend( {}, defaultOptions, $carousel.data( 'slider_options' ) );

            $carousel.slick( slickOptions );

        },
	});
};
